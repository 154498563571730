#actions .row{
  margin-right: auto;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
  background-color: darkslategrey !important;
}

.container{
  background-color: whitesmoke
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

form{
  padding-bottom: 10px;
}

.form-col{
  margin-left:10px !important;
}

.header{
  font-size: x-large;
  font-weight: 600;
  line-height: 1.5em;
  padding: 10px;
  background-color: dimgray;
  vertical-align: middle;
}
.header img{
  width:45px;
  height: 45px;
} 
.header div{
  padding-top:5px;
  display: inline-block;
}

.invalid .form-control:invalid{
  border:1px red inset;
}


.img-preview-wrapper{
  width:100%;
  height:auto;
  min-height: 200px;
  box-sizing: border-box;
  padding: 10px;
  
}

.img-preview-wrapper{
  width:100%;
  height:auto;
  min-height: 200px;
  box-sizing: border-box;
  padding: 10px;
  
}

.img-preview{
  background-position: center;
  background-size: 180px;
  background-image: url('person-vcard-fill.svg');
  background-repeat: no-repeat;
  border: 2px solid grey;
  border-radius: 15px;
}


.img-loading.img-preview{
  background-image: none;
}


.img-preview.ID{
  max-height: 350px;
}

.img-preview.ID .id-img-holder{
  max-height: 300px;
  clip-path: inset(5px 5px 5px 5px);
}

.img-preview.ID .id-btn-holder div{
  margin: 0 auto;
  display: inline-block;
  width: auto;
  text-align: center;
}

.img-loading.img-preview.selfy{
  background-image:none;
}

.img-preview.selfy{
  background-image: url('person-bounding-box.svg');
}


.img-preview img{
  width:100%;
}
.img-preview img.showing{
  visibility: visible;
}


.img-preview button{

}


.img-load-spinner{
  width: 100px !important;
  height: 100px !important;
  margin-top:50%;
}


/** Forms **/

.form-control:invalid{
  border-color: red;
}

.loading-holder{
  vertical-align: middle;
  text-align: center;
  min-height: 800px;

}

.loading-spinner{
  background-color: red;
}

/** Modal **/

.modal.show .modal-dialog{
  max-width: 80% !important;
}

.modal-dialog .modal-content{
  max-height: 800px;
  overflow-y: scroll;
}

.modal.show .modal-body{
  text-align: center;
}

.modal-body {
  padding: 5px;
  overflow-x: scroll;
  overflow-y: scroll;
}

.new-voter{
  background-color: gray;
}

.new-voter.container-md{
  background-color: beige;
}

.tester {
  background-color: green;
}

.tester2{
  background-color: blue;
}

